<template>
    <div class="page-wrap py-3">
        <div class="session-form-hold">
            <div>
                <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    top
                    color="white"
                />
                <v-card-text class="text-center my-0 py-0">
                    <div style="display: flex; flex-direction: column; align-items: center">
                        <!-- <span style="color: white"> {{ $t('users.login.help.selectMainLang') }} </span>
                        <language-selector color="white" /> -->

                        <v-img
                            class="logo mb-6"
                            src="@/assets/img/logo-default.png"
                            alt="Logo"
                            max-width="120px"
                            contain
                        />
                    </div>

                    <span class="red--text mb-2">
                        {{ serverError }}
                    </span>

                    <!-- <div class="mt-6">
                        <span
                            style="color: white"
                            v-html="$t('auth.microsoft.title')"
                        />

                        <v-btn
                            class="mt-2"
                            block
                            :color="getDefaultSecondaryColor()"
                            dark
                            style="z-index: 3;"
                            x-large
                            @click="loginMicrosoft"
                        >
                            <v-img
                                class="mr-2"
                                src="@/assets/img/svg/microsoft_logo.svg"
                                alt="Login with Microsoft"
                                max-width="28px"
                                contain
                            />
                            {{ $t('auth.microsoft.buttonLoging') }}
                        </v-btn>
                    </div> -->

                    <v-form
                        ref="form"
                        v-model="isValid"
                        class="px-8"
                    >
                        <!-- <span
                            style="color: white"
                            v-html="$t('auth.login.title')"
                        /> -->
                        <v-text-field
                            v-model="email"
                            solo
                            :label="$t('users.login.username')"
                            :rules="[rules.required]"
                            validate-on-blur
                            @keyup="serverError = ''"
                            dense
                            @keydown.enter="formSubmit"
                        />

                        <v-text-field
                            v-model="ePassword"
                            solo
                            :append-icon="showIcon ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showIcon ? 'text' : 'password'"
                            name="input-10-2"
                            :label="$t('users.login.password')"
                            :rules="[rules.required, rules.validPassword]"
                            validate-on-blur
                            @click:append="showIcon = !showIcon"
                            @keyup="serverError = ''"
                            dense
                            @keydown.enter="formSubmit"
                        />
                        <!--                        <v-checkbox
                            v-model="checkbox1"
                            :label="$t('users.login.rememberPass')"
                        />-->
                        <!--                      :style="`opacity: ${(!isValid && !serverCheck) ? '0.2' : '1'}`"-->

                        <!--                        <div style="width: 100%; background-color: #28344a; border-radius: 3px; color: white; font-size: medium">
                            <p
                                style="padding: 8px"
                                v-html="$t('global.help.recommendedBrowser')"
                            />
                        </div>-->

                        <div class="buttons-area">
                            <v-btn
                                block
                                color="#E39C32"
                                dark
                                @click="formSubmit"
                                elevation="0"
                            >
                                <!-- <v-icon left> mdi-login </v-icon> -->
                                {{ $t('auth.login.buttonLogin') }}
                            </v-btn>

                            <router-link
                                to="/app/sessions/forgot"
                                class="forgot-password mt-2 pa-3"
                            >
                                {{ $t('users.login.forgotPassword') }}
                            </router-link>
                        </div>
                        <!--                        <v-btn
                            v-if="canSignUp"
                            text
                            small
                            color="white"
                            to="/app/sessions/sign-up-2"
                        >
                            {{ $t('users.login.createNewAccount') }}
                        </v-btn>-->
                    </v-form>
                </v-card-text>
            </div>
        </div>

        <!--        <v-img
            class="parpadea"
            src="@/assets/img/background_person.png"
            alt=""
            max-height="35vh"
            contain
        />-->

        <confirm-dialog ref="confirm" />
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import rules from '@/helpers/rulesValidate';
import LanguageSelector from '@/components/common/LanguageSelector';
import ConfirmDialog from '@/components/common/ConfirmDialog';
import companyMixin from '@/mixins/companyMixin';

export default {
    name: 'Login',
    metaInfo: {
        // title will be injected into parent titleTemplate
        // title: this.$t('users.login.title')
    },
    components: { LanguageSelector, ConfirmDialog },
    mixins: [companyMixin],
    data() {
        return {
            isValid: true,
            serverCheck: false,
            serverError: '',
            showIcon: false,
            password: 'Password',
            checkbox1: true,
            checkbox2: false,
            email: '',
            ePassword: '',
            loading: false,
            rules: {
                ...rules
            },
            canSignUp: false
        };
    },
    computed: {
        ...mapGetters(['loggedInUser', 'error'])
    },
    created() {
        // this.loadConfig();

        const lang = this.$route.params?.lang;
        if (lang) {
            // this.$vuetify.lang.current = item.value;
            this.$root.$i18n.locale = lang;
        }
    },
    methods: {
        ...mapActions(['login']),
        /* async loadConfig() {
            const configs = await axios
                .get('configs/1');

            let options = configs?.data?.data?.attributes?.options;
            if (options) {
                options = JSON.parse(options);
                this.canSignUp = options?.canRegister ?? false;
            }
        }, */
        loginMicrosoft() {
            window.location.replace(process.env.VUE_APP_SERVER + '/authAzure');
        },
        async formSubmit() {
            if (!this.$refs.form.validate()) return false;

            try {
                const result = await this.login({
                    username: this.email.trim(),
                    password: this.ePassword
                });

                if (result !== true) {
                    const error = result?.error;
                    if (error === 'change_password') {
                        if (
                            await this.$refs.confirm.open(this.$t('users.login.newPassMSG.title'), '', {
                                noCancel: true
                            })
                        ) {
                            const token = result?.token;
                            this.$router.push('/app/sessions/pass-reset/?token=' + token);
                        }
                    } else {
                        switch (error) {
                            case 'invalid_credentials':
                            case 'connection_issues':
                            case 'invalid_password':
                            case 'user_disabled':
                            case 'need_different_password':
                                this.serverError = this.$t(`errors.${error}`);
                                break;

                            default:
                                this.serverError = this.$t('errors.connection_issues');
                        }
                    }
                }
            } catch (error) {
                this.serverError = this.$t('errors.connection_issues');
            }
        },
        goToDownloadInstruction() {
            window.location.href = 'https://itpaerovaluesawards.com/download/';
        }
    },
    watch: {
        // loading (val) {
        //   if (!val) return
        //   setTimeout(() => (this.loading = false), 2000)
        // },
        loggedInUser(val) {
            if (val && val.token && val.token.length > 0) {
                // this.makeToast("success", "Successfully Logged In");
                /* console.log('logged in successfully ') */
                this.loading = true;
                setTimeout(() => {
                    /* console.log(val.roles)
                    console.log('val.roles') */
                    if (val.roles?.find(role => role.name === 'Admin')) {
                        this.$router.push('/app/admin');
                    } else {
                        this.$router.push('/');
                    }
                }, 500);
            }
        },
        error(val) {
            if (val != null) {
                // this.makeToast("warning", val.message);
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.page-wrap {
    //background-color: #242939 !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100%;
}

.session-form-hold {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.logo {
    margin-top: 160px;
}

.forgot-password {
    color: black;
    display: block;
}
</style>
